export const api = {
  USER_GET: '/user/userinfo',
  SWITCH_PARTNER: '/assistant/switchPartner/:partnerCode',
  CONTRACT_CREATE: '/contract/new',
  CONTRACT_CANCEL: '/contract/cancel',
  CONTRACT_RESUME: '/contract/resume',
  CONTRACT_RESUME_COMPLETED: '/contract/completed/resume',
  CONTRACT_STEP: '/contract/step',
  CONTRACT_DOCUMENTATION: '/contract/documentation',
  CONTRACT_DOCUMENTATION_SIGNATURE: '/contract/documentation/signature',
  CONTRACT_OPEN_LIST: '/contract/list/open',
  CONTRACT_COMPLETED_LIST: '/contract/list/completed',
  CONTRACT_COMPLETE: '/contract/complete',
  CONTRACT_FINALIZE: '/contract/finalize',
  CONTRACT_REOPEN: '/contract/reopen',
  CONTRACT_START_DATA_PREPARATION: '/contract/render/request',
  CONTRACT_RENDER_BCUPDATE: '/contract/render/bcupdate',
  // CONTRACT_CHECK_DATA_STATE: '/contract/render/check',
  CONTRACT_SIGN: '/contract/signature',
  CONTRACT_SIGNATURE_CANCEL: '/contract/signature/cancel',
  CONTRACT_SIGNATURE_REMIND: '/contract/signature/remind',
  CONTRACT_ATTACHMENT_UPLOAD: '/contract/attachment/upload',
  CONTRACT_ATTACHMENT_DOWNLOAD: '/contract/attachment/data',
  CONTRACT_DOCUMENT_DOWNLOAD: '/contract/document/data',
  CONTRACT_ATTACHMENT_DELETE: '/contract/attachment/remove',
  CONTRACT_PRODUCERS: (contractId: string) => `/values/producers/contract/${contractId}`,
  CONTRACT_METADATA: '/contract/metadata',
  EDWARD_TRANSFER: '/contract/edward/account',
  CLIENT_GET: '/client/get',
  CLIENT_LOAD: '/client/load',
  CLIENT_SAVE: '/client/save',
  CLIENT_NEW_PHYSICAL: '/client/new/PHYSICAL',
  CLIENT_NEW_LEGAL: '/client/new/LEGAL',
  CLIENT_VALIDATE_ICO: (companyNumber: string) => `/person/find/companyDetail/${companyNumber}`,
  PERSON_AGREEMENT_SEND: '/send/agreement',
  PERSON_AGREEMENT_SCAN: '/doc/agreement/upload',
  PERSON_TRANSFER_SIGNATURE_CHECK: '/person/transfer/signature/check',
  PERSON_CONTRACT_SIGNATURE_CHECK: '/person/contract/signature/check',
  PERSON_FIND: '/person/find',
  PERSON_FIND_PHYSICAL: '/person/find/physical',
  PERSON_FIND_ADDRESS: '/person/find/address',
  PERSON_NEW_PHYSICAL: '/person/new/physical',
  INFO_SEND: '/send/info',
  INFO_PRINT: '/print/info',
  INFO_PRINT_DATA: '/print/info/data',
  DOCUMENTS_CLIENT_SEND: '/send/all/client',
  DOCUMENTS_CLIENT_SEND_COMPLETED: '/send/completed',
  DOCUMENTS_CLIENT_PRINT: '/print/all/client',
  DOCUMENTS_CLIENT_PRINT_DATA: '/print/all/client/data',
  DOCUMENTS_INTERNAL_SEND: '/send/all/internal',
  DOCUMENTS_INTERNAL_PRINT: '/print/all/internal',
  DOCUMENTS_INTERNAL_PRINT_DATA: '/print/all/internal/data',
  DOCUMENTS_PRINT_ALL: '/print/all/data',
  DOCUMENTS_CONTRACT_SET_NUMBER: '/doc/update/epponly/number',

  /**
   * @param type - url param, type of questionnaire
   * @method POST
   */
  /**
   * url params
   * @param clientCardId
   */
  INVESTMENT_QUESTIONNAIRE_GET_COMPLETED: (type: string) => `/questionnaire/${type}/complete/get`,
  /**
   * url params
   * @param questionnaireId
   */
  INVESTMENT_QUESTIONNAIRE_EVALUATE_COMPLETED: `/questionnaire/complete/evaluate`,
  /**
   * url params
   * @param clientCardId - clientRootId
   */
  INVESTMENT_QUESTIONNAIRE_CREATE: (type: string) => `/questionnaire/${type}/create`,
  /**
   * url params
   * @param clientCardId - clientRootId
   * @param section - section of the questionnaire
   * @param questionnaireId
   */
  INVESTMENT_QUESTIONNAIRE_GET: (type: string) => `/questionnaire/${type}/part/get`,
  // ?section=${sectionId}&questionnaireId=${questionnaireId}
  /**
   * url params
   * @param questionnaireId
   * @method POST
   * @param answers
   */
  INVESTMENT_QUESTIONNAIRE_EDIT: (type: string) => `/questionnaire/${type}/part/edit`,
  /**
   * url params
   * @param questionnaireId
   * @param contractId
   */
  INVESTMENT_QUESTIONNAIRE_EVALUATE: `/questionnaire/evaluate`,
  INVESTMENT_QUESTIONNAIRE_EMPTY_SEND: '/send/questionnaire',
  INVESTMENT_QUESTIONNAIRE_EMPTY_PRINT: '/print/questionnaire',
  INVESTMENT_QUESTIONNAIRE_EMPTY_PRINT_DATA: '/print/questionnaire/data',
  INVESTMENT_QUESTIONNAIRE_EVALUATION_SEND: '/send/evaluation',
  INVESTMENT_QUESTIONNAIRE_EVALUATION_PRINT: '/print/evaluation',
  INVESTMENT_QUESTIONNAIRE_EVALUATION_PRINT_DATA: '/print/evaluation/data',

  VALUES_COUNTRIES: '/values/country',
  VALUES_FEE_TYPES: '/values/feetype',
  VALUES_REGULAR_FEE_TYPES: '/invest/regular/feetype',
  VALUES_CURRENCIES: '/values/currency',
  VALUES_INDEXES: '/values/indexes',
  VALUES_FREQUENCY: '/values/frequency',
  VALUES_ACTIVE_PRODUCERS: '/values/producers',
  VALUES_ALL_PRODUCERS: '/values/all/producers',
  TRANSFER_PRODUCERS: '/transfer/producers',
  VALUES_PRODUCERS_CONTRACTS: '/values/producers/contract',
  PRODUCTS_LIST: '/product/list',
  PRODUCTS_SELECT: '/product/select',
  PRODUCTS_AUTOCOMPLETE: '/product/search',
  INVESTMENTS_GET: '/invest/get',
  INVESTMENTS_SAVE: '/invest/save',
  INVESTMENTS_REMOVE: '/invest/remove',
  INVESTMENTS_MAX_FEE: '/invest/maxfee',
  INVESTMENTS_PORTFOLIO: '/invest/portfolio',
  INVESTMENTS_CHECK_CONSEQ_FEES: '/invest/checkConseqFees',
  DOC_GET_CONSEQ: '/doc/get/conseq',
  DOC_UPDATE_CONSEQ: '/doc/update/conseq',
  DOC_GET_INVESTIKA: '/doc/get/investika',
  DOC_UPDATE_INVESTIKA: '/doc/update/investika',
  DOC_GET_AMUNDI: (contractId: string) => `/contract/${contractId}/amundi`,
  DOC_UPDATE_AMUNDI: (contractId: string) => `/contract/${contractId}/amundi`,

  DOC_TESLA_GET: '/doc/get/tesla',
  DOC_TESLA_UPDATE: '/doc/update/tesla',
  DOC_TESLA_VALIDATE: `/doc/validate/tesla/`,
  DOC_CODYA_GET: (contractId: string) => `/contract/${contractId}/codya`,
  DOC_CODYA_UPDATE: (contractId: string) => `/contract/${contractId}/codya`,

  DOC_GET_AML: '/aml/get',
  DOC_UPDATE_AML: '/aml/update',
  DOC_GET_GENERALI: '/doc/get/generali',
  DOC_UPDATE_GENERALI: '/doc/update/generali',
  DOC_GET_SUMMARY: '/doc/get/summary',
  DOC_UPDATE_SUMMARY: '/doc/update/summary',
  DOC_GET_NNIP: '/doc/get/nnip',
  DOC_UPDATE_NNIP: '/doc/update/nnip',
  DOC_GET_COLOSSEUM: (contractId: string) => `/contract/${contractId}/colosseum`,
  DOC_UPDATE_COLOSSEUM: (contractId: string) => `/contract/${contractId}/colosseum`,
  PRINT_TRANSFER_CONSEQ: '/transfer/conseq/data',
  PRINT_TRANSFER_INVESTIKA: '/transfer/investika/data',
  PRINT_TRANSFER_NN: '/transfer/nn/data',
  PRINT_TRANSFER_TESLA: '/transfer/tesla/data',
  PRINT_TRANSFER_AMUNDI: '/transfer/amundi/data',
  PRINT_TRANSFER_JAT: '/transfer/jat/data',
  PRINT_TRANSFER_GENERALI: '/transfer/generali/data',
  SIGN_TRANSFER_CONSEQ_CHECK: '/transfer/conseq/check',
  SIGN_TRANSFER_CONSEQ: '/transfer/conseq/signature',
  SIGN_TRANSFER_AMUNDI_CHECK: '/transfer/amundi/check',
  SIGN_TRANSFER_AMUNDI: '/transfer/amundi/signature',
  SIGN_TRANSFER_INVESTIKA_CHECK: '/transfer/amundi/check',
  SIGN_TRANSFER_INVESTIKA: '/transfer/amundi/signature',
  CONTRACT: {
    EXECUTION_ONLY: {
      INSTRUMENTS_GET: '/instrument/get',
      INSTRUMENT_SAVE: '/instrument/save',
      INSTRUMENT_REMOVE: '/instrument/remove',
      INSTRUMENTS_PRODUCERS: '/instrument/producers',
      PROVIDER_CONTRACT_SAVE: '/providerContract/save',
      PROVIDER_CONTRACT_REMOVE: '/providerContract/remove',
    },
    ADVISORY: {
      TARGETS_GET: '/target/get',
      TARGET_SAVE: '/target/save',
      TARGET_REMOVE: '/target/remove',
      QUESTIONNAIRE_TARGET_CREATE: '/questionnaire/TARGET/create',
      QUESTIONNAIRE_TARGET_EDIT: '/questionnaire/TARGET/part/edit',
      QUESTIONNAIRE_TARGET_GET: '/questionnaire/TARGET/part/get',
      POSSESSION_GET: '/possession/get',
      POSSESSION_SAVE: '/possession/save',
      POSSESSION_REMOVE: '/possession/remove',
      PORTFOLIO_GET: '/portfolio/get',
      PORTFOLIO_SAVE: '/portfolio/save',
      PORTFOLIO_REMOVE: '/portfolio/remove',
      PORTFOLIO_RECOMMEND_CHANGE: '/portfolio/recommend/change',
      PORTFOLIO_RECOMMEND_CANCEL: '/portfolio/recommend/cancel',
      PORTFOLIO_RECOMMEND_REMOVE: '/portfolio/recommend/remove',
      PORTFOLIO_INVESTMENT_SAVE: '/portfolio/investment/save',
      PORTFOLIO_INVESTMENT_REMOVE: '/portfolio/investment/remove',
    },
  },
  MATERIALS: {
    WIKI_RECORDS_LIST: '/wiki/get',
    WIKI_RECORD: '/wiki/record',
    WIKI_CATEGORIES_LIST: '/admin/wiki/category/list',
    WIKI_CATEGORY: '/wiki/category',
    INSTRUMENTS_GET: '/present/index',
    INVESTMENTS_GET: '/present/invest',
    CALCULATORS: {
      EXPECTED: '/present/expected',
      CONTRIBUTION: '/calc/contribution',
      RENT_COMBINED: '/present/combi',
      RENT_REQUIRED_RENT: '/calc/rent',
      RENT_AVAILABILITY: '/present/rent', //'/calc/availability',
      RENT_INITIAL_AMOUNT: '/calc/initial',
      RENT_PRESENT_COMBINED: '/present/rent',
      MORTGAGE: '/present/loan',
    },
  },
  REPORTING: {
    PARTNER_NAVIGATION: '/partner/navigation',
    STATISTICS: {
      ASSETS: '/api/v1/reporting/overview/assets',
      CHARTS: '/api/v1/reporting/overview/charts',
      MARKERS: '/api/v1/reporting/overview/markers',
      CLIENTS: '/report/overview/clients',
      PARTNERS: '/api/v1/reporting/overview/partners',
      CONTRACTS: '/report/overview/contracts',
      PRODUCERS: '/api/v1/reporting/overview/producers',
    },
    CLIENT: {
      ASSETS: '/report/portfolio/assets',
      CHARTS: '/report/portfolio/charts',
      MARKERS: '/report/portfolio/markers',
      PRODUCTS: '/report/portfolio/products',
      PRODUCT_SAVE: '/report/portfolio/product/save',
      PRODUCT_REMOVE: '/report/portfolio/product/remove',
      INFO: '/report/portfolio/info',
      PRODUCERS: '/report/portfolio/producers',
      PRODUCERS_POST: (producersPost: string) => `/report/portfolio/transaction/${producersPost}`,
    },
    CONTRACT: {
      ASSIGNED: '/report/contract/assigned/:partner',
      UNASSIGNED: '/report/contract/unassigned/:partner',
      UNASSIGNED_COUNT: '/report/contract/unassigned/count/:partner',
      ASSIGN: '/report/contract/client/assign',
      UNASSIGN: '/report/contract/client/unassign',
    },
  },
  ADMIN: {
    INDEX_IMPORT: '/admin/index/import',
    INDEX_LIST: '/admin/index/list',
    PACKAGE_IMPORT: '/admin/package/import',
    PACKAGE_LIST: '/admin/package/list',
    FUND_IMPORT: '/admin/fund/import',
    FUND_LIST: '/admin/fund/list',
    TARIFF_IMPORT: '/admin/tariff/import',
    TARIFF_LIST: '/admin/tariff/list',
    PRODUCER_OVERVIEW: '/admin/producer/overview',
    PRODUCER_ENABLE: '/admin/producer/enable',
    PRODUCER_DISABLE: '/admin/producer/disable',
    CONTRACT_ENABLE: '/admin/contract/enable',
    CONTRACT_DISABLE: '/admin/contract/disable',
    PRODUCER_SAVE: '/admin/producer/save',
    RECOMMENDED_OVERVIEW: '/admin/recommended/overview',
    RECOMMENDED_REMOVE: '/admin/recommended/remove',
    RECOMMENDED_ADD: '/admin/recommended/add',
    RECOMMENDED_UNASSIGNED: '/admin/recommended/unassigned',
    WIKI_CATEGORY_SAVE: '/admin/wiki/category/save',
    WIKI_CATEGORY_DELETE: '/admin/wiki/category/delete',
    WIKI_RECORD_SAVE: '/admin/wiki/record/save',
    WIKI_RECORD_DELETE: '/admin/wiki/record/delete',
    PORTFOLIO_LIST: '/admin/portfolio/list',
    PORTFOLIO_SAVE: '/admin/portfolio/save',
    PORTFOLIO_DELETE: '/admin/portfolio/delete',
    PORTFOLIO_DETAIL: '/admin/portfolio/get',
    TARGET_MARKET_IMPORT: '/admin/targetmarket/import',
    IMPORTED_PRODUCERS_CSV_FILES_STATUS: '/api/v1/import/file/active-contract-balance/status',
    IMPORT_PRODUCERS_CSV: '/api/v1/import/file/active-contract-balance',
    IMPORTED_FUND_PRICE_HISTORY_STATUS: '/api/v1/import/file/fund-price-history/status',
    IMPORT_FUND_PRICE_HISTORY: '/api/v1/import/file/fund-price-history',
    NEWS: {
      BT_SAVE: '/admin/news/bt/save',
      PRODUCER_SAVE: '/admin/news/producer/save',
      BT_DELETE: '/admin/news/bt/delete',
      PRODUCER_DELETE: '/admin/news/producer/delete',
    },
    SIGNIN_LIST: '/admin/signing-requests/list',
    SIGNIN_DETAIL: '/admin/signing-requests/detail',
    SIGNIN_REACTIVATE: '/admin/signing-requests/reactivate',
  },
  NEWS: {
    BT_LIST: '/news/bt/list',
    PRODUCER_LIST: '/news/producer/list',
    PRODUCER_LIST_PAGING: '/news/producer/paging/list',
    BT_DETAIL: '/news/bt/detail/:entityId',
    PRODUCER_DETAIL: '/news/producer/detail/:entityId',
  },
  ATTACHMENTS: {
    ADD: '/attachment/add',
    DELETE: '/attachment/delete',
    GET: '/attachment/get/:attachmentId',
  },
  MICROSITE: {
    INVESTMENT_QUESTIONNAIRE_NEW: '/ext/questionnaire/new/:link',
    INVESTMENT_QUESTIONNAIRE_GET: '/ext/questionnaire/part/get/:link',
    INVESTMENT_QUESTIONNAIRE_EDIT: '/ext/questionnaire/part/edit/:link',
    INVESTMENT_QUESTIONNAIRE_EVALUATE: '/ext/questionnaire/evaluate/:link',
    TARGET_QUESTIONARY: '/ext/questionnaire/target/new/:link',
    LOAD_CORE_DATA: '/ext/load/:link',
    COMPLETE: '/ext/complete/:link',
    SUMMARY: '/ext/summary/get/:link',
    QUESTIONNAIRE_TARGET_CREATE: '/ext/questionnaire/target/new/:link',
    QUESTIONNAIRE_TARGET_EDIT: '/ext/questionnaire/part/edit/:link',
    QUESTIONNAIRE_TARGET_GET: '/ext/questionnaire/part/get/:link',
    TARGETS: '/ext/target/get/:link',
    TARGET_REMOVE: '/ext/target/remove/:link',
    TARGET_SAVE: '/ext/target/save/:link',
    POSSESSION_GET: '/ext/possession/get/:link',
    POSSESSION_SAVE: '/ext/possession/save/:link',
    POSSESSION_REMOVE: '/ext/possession/remove/:link',
    CURRENCIES: '/ext/currency/:link',
  },
  FROM_PARENT_COMPANY: '/partner/isFromParentCompany',
};
